/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {ObjectToQuery} from "@/plugins/helper";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)",
    barImage: "",
    drawer: localStorage.getItem("drawer") == null ?
        true : localStorage.getItem("drawer") == "true",
    drawerMessage: null,
    school: {},
    schools: {},
    alerts: {},
    UploaderUrl: "",
    idToken: localStorage.getItem("token"),
    overlay: false,
    userDirectorates: null,
    userGovernorates: null,
    years: null,
    servers: null,
    types: null,
    classes: null,
  },
  mutations: {
    setCurrentPage(state, data) {
      state.schools.current_page = data;
    },
    toggleAlert(state, name) {
      let id = state.alerts.indexOf(name);
      if (id !== -1) {
        state.alerts.splice(id, 1);
      } else {
        state.alerts.push(name);
      }
    },
    setSchools(state, data) {
      state.schools = data;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
      localStorage.setItem("drawer", payload);
    },
    SET_SCHOOL(state, payload) {
      state.school = payload;
      //localStorage.setItem("drawer", payload);
    },
    SET_DRAWER_Message(state, payload) {
      state.drawerMessage = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
    SET_UPLOADER(state, payload) {
      state.UploaderUrl = payload;
    },
    overlay(state, overlay) {
      state.overlay = overlay;
    },
    authUser(state, userData) {
      state.idToken = userData.token;
      state.expiration = userData.expiration;
    },
  },
  getters: {
    isLoading(state) {
      return state.overlay;
    },
    isInRole: (state) => (role) => {
      var is = jwt_decode(state.idToken).roles.some((s) => s == role);
      return is;
    },
    userName() {
      return JSON.parse(localStorage.getItem("userData")).name;
    },
  },
  actions: {
    async GetUserAlerts({state, dispatch}, skipTimer = null) {
      try {
        if (state.idToken) {
          const response = await axios.get("GetUserAlerts", {noToast: true});
          state.alerts = response.data;
        }
      } finally {
        if (!skipTimer) {
          setTimeout(() => {
            dispatch('GetUserAlerts');
          }, 300000);
        }
      }
    },
    async GetUserDirectorates({state}) {
      if (!state.userDirectorates) {
        const response = await axios.get("GetUserDirectorates", {noToast: true});
        state.userDirectorates = response.data.data;
      }
      return state.userDirectorates;
    },
    async GetUserGovernorates({state}) {
      if (!state.userGovernorates) {
        const response = await axios.get("GetUserGovernorates", {noToast: true});
        state.userGovernorates = response.data.data;
      }
      return state.userGovernorates;
    },
    async GetYears({state}) {

      const response = await axios.get("GetYears", {noToast: true});
      state.years = response.data.data;

      return state.years;
    },
    async GetServers({state}) {
      if (!state.servers) {
        const response = await axios.get("servers/?onlyAllow=1", {noToast: true});
        state.servers = response.data.data;
      }
      return state.servers;
    },
    async GetSchoolsTypes({state}) {
      if (!state.types) {
        const response = await axios.get("GetSchoolsTypes", {noToast: true});
        state.types = response.data.data;
      }
      return state.types;
    },
    async GetClasses({state}) {
      if (!state.classes) {
        const response = await axios.get("advertisements/classes", {noToast: true});
        state.classes = response.data.data;
      }
      return state.classes;
    },
    async getSchools({commit}, data) {
      var url = "";
      if (data.name != null && data.name != "" && data.name != undefined) {
        url += "&name=" + data.name;
      }
      if (data.domain_scid != null && data.domain_scid != "" && data.domain_scid != undefined) {
        url += "&domain_scid=" + data.domain_scid;
      }

      if (data.free != null || data.free != undefined) {
        url += "&free=" + data.free;
      }

      if (data.active != null || data.active != undefined) {
        url += "&active=" + data.active;
      }

      if (data.owner != null || data.owner != undefined) {
        url += "&owner=" + data.owner;
      }

      if (data.createdAt != null || data.createdAt != undefined) {
        url += "&createdAt=" + data.createdAt;
      }

      if (data.year_id != null || data.year_id != undefined) {
        url += "&year_id=" + data.year_id;
      }

      if (data.financial_status_student != null || data.financial_status_student != undefined) {
        url += "&financial_status_student=" + data.financial_status_student;
      }

      if (data.financial_status_teacher != null || data.financial_status_teacher != undefined) {
        url += "&financial_status_teacher=" + data.financial_status_teacher;
      }

      if (data.financial_status_driver != null || data.financial_status_driver != undefined) {
        url += "&financial_status_driver=" + data.financial_status_driver;
      }

      if (data.directorate_id != null || data.directorate_id != undefined) {
        url += "&directorate_id=" + data.directorate_id;
      }
      if (data.governorate_id != null || data.governorate_id != undefined) {
        url += "&governorate_id=" + data.governorate_id;
      }
      if (data.deleted) {
        url += "&deleted";
      }
      if (data.lessStudents) {
        url += "&lessStudents";
      }
      if (data.agency_id !== null) {
        url += "&agency_id=" + data.agency_id;
      }
      if (data.financeFilter) {
        url += "&" + ObjectToQuery({...data.financeFilter})
      }

      const res = await axios.get("GetSchools?page_size=50&page=" + data.pageNumber + url, {noToast: true});
      commit("setSchools", res.data.data);
      return res.data.data;
    },

    async exportSchools({commit}, data) {
      var url = "";
      if (data.name != null && data.name != "" && data.name != undefined) {
        url += "&name=" + data.name;
      }
      if (data.domain_scid != null && data.domain_scid != "" && data.domain_scid != undefined) {
        url += "&domain_scid=" + data.domain_scid;
      }

      if (data.free != null || data.free != undefined) {
        url += "&free=" + data.free;
      }

      if (data.active != null || data.active != undefined) {
        url += "&active=" + data.active;
      }

      if (data.owner != null || data.owner != undefined) {
        url += "&owner=" + data.owner;
      }

      if (data.createdAt != null || data.createdAt != undefined) {
        url += "&createdAt=" + data.createdAt;
      }

      if (data.year_id != null || data.year_id != undefined) {
        url += "&year_id=" + data.year_id;
      }

      if (data.financial_status_student != null || data.financial_status_student != undefined) {
        url += "&financial_status_student=" + data.financial_status_student;
      }

      if (data.financial_status_teacher != null || data.financial_status_teacher != undefined) {
        url += "&financial_status_teacher=" + data.financial_status_teacher;
      }

      if (data.financial_status_driver != null || data.financial_status_driver != undefined) {
        url += "&financial_status_driver=" + data.financial_status_driver;
      }

      if (data.directorate_id != null || data.directorate_id != undefined) {
        url += "&directorate_id=" + data.directorate_id;
      }
      if (data.governorate_id != null || data.governorate_id != undefined) {
        url += "&governorate_id=" + data.governorate_id;
      }
      if (data.deleted) {
        url += "&deleted";
      }
      if (data.lessStudents) {
        url += "&lessStudents";
      }

      if (data.agency_id !== null) {
        url += "&agency_id=" + data.agency_id;
      }

      if (data.financeFilter) {
        url += "&" + ObjectToQuery({...data.financeFilter})
      }
      const getFileName = (fileBlob, defaultFileName) => {
        const contentDisposition = (fileBlob).headers['content-disposition']

        if (contentDisposition) {
          const fileNameIdentifier = 'filename='
          const filenamePosition = contentDisposition.indexOf(fileNameIdentifier)

          if (~filenamePosition)
            return contentDisposition.slice(filenamePosition + fileNameIdentifier.length, contentDisposition.length).replace(/"/g, '')
        }

        return defaultFileName
      }
      const downloadFile = (fileBlob, fileName) => {
        const url = URL.createObjectURL(new Blob([fileBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', `${fileName}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        link.style.display = 'none'
        window.URL.revokeObjectURL(url)
      }

      const fileBlob = await axios.get("exportSchools?" + url, {
        responseType: 'blob',
        noToast: true,
        failureToast: true
      });
      const fileName = getFileName(fileBlob, 'export.xlsx')
      downloadFile(fileBlob.data, fileName)
    },
    overlay({commit}, overlay) {
      commit("overlay", overlay);
    },
    login({commit}, data) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("userData", JSON.stringify(data.userData));
      commit("authUser", {
        token: data.token,
        expiration: data.expiration,
      });
      axios.defaults.headers.get["Authorization"] =
          "Bearer " + data.token;
      router.push({
        name: "Dashboard",
      });
    },
    logout({commit}) {
      if (this.$vuetify) {
        localStorage.setItem("dark", this.$vuetify.theme.dark);
        this.$vuetify.rtl = localStorage.getItem("lang") == "ar" ? true : false;
      }
      if (this.$i18n) {
        this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
        localStorage.setItem("lang", this.$i18n.locale);
      }

      localStorage.removeItem("token");
      localStorage.removeItem("userData");

      axios.defaults.headers.get["Authorization"] = null;
    },
  },
});
